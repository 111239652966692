import React, { useEffect } from "react";
import loadable from "@loadable/component";
import { useAppState } from "@state";
import { LazyMotion } from "framer-motion";
import classNames from "classnames";

import "../../pcss/fonts/fontsromero.pcss";

const Modal = loadable(() => import("@organisms/Modal"));
const RomeroHeader = loadable(() => import("@organisms/RomeroHeader"));
const Themer = loadable(() => import("../atoms/Themer"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};
const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, path }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  const [{ layout }] = useAppState();
  const { theme } = layout;
  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div
        id="app_layout"
        className={classNames("romero w-full transition duration-500", {
          "bg-cream": theme === "light",
          "bg-black": theme === "dark",
        })}
      >
        <RomeroHeader />
        {children}
        <Modal />
        <Themer />
        <div id="fb-root" />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
